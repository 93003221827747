<template>
  <div>



      <b-row>
        <b-col cols="6">

          <b-form-input
            class="mt-2"
            id="filter-input"
            v-model="filters.text"
            type="search"
            :placeholder="$t('COMMON.SEARCH')"
          ></b-form-input>

        </b-col>

        <b-col cols="6">
          <div class="d-sm-flex justify-content-end align-items-center align-bottom w-100 mb-4">
            <a
              href="#"
              class="btn btn-primary font-weight-bolder font-size-sm mr-2 my-2"
              @click.prevent="$emit('create')"
              ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{$t('SWISH_MERCHANT.CREATE_MERCHANT')}}</a
            >
          </div>

        </b-col>
      </b-row>

    <b-row>
      <b-col lg="6" md="6" sm="12">

        <div class="bottom-alignment">
          <strong>{{ $t('COMMON.NUM_RECORDS') }}:</strong> {{ total_rows }}
        </div>

      </b-col>
      <b-col lg="6" md="6" sm="12">
        <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100">
          <b-form-select
            id="per-page"
            class="mr-sm-2 mx-0"
            style="max-width: 100px;"
            v-model="per_page"
            :options="[{ value: 100, text: '100' }, { value: 250, text: '250' }]"
          ></b-form-select>
          <b-pagination
            class="mb-0"
            v-model="current_page"
            :total-rows="total_rows"
            :per-page="per_page"
            aria-controls="swish_merchant-table"
          ></b-pagination>
        </div>
      </b-col>
    </b-row>



    <!-- Table -->
    <div class="table-container mt-2">
      <b-table
        class="table-striped"
        :items="ajax_pagination"
        :fields="fields"
        :per-page="per_page"
        :current-page="current_page"
        head-variant="light"
        ref="swish_merchant-table"
      >
      <template #cell(status)="data">

        <v-chip style="font-size: 10px;" v-if="data.item.status === 'PENDING'" color="orange" outlined>
          {{ $t('SWISH_MERCHANT.STATUSES.PENDING') }}
        </v-chip>

        <v-chip style="font-size: 10px;" v-if="data.item.status === 'VALID_CERTS'" color="orange" outlined>
          {{ $t('SWISH_MERCHANT.STATUSES.VALID_CERTS') }}
        </v-chip>

        <v-chip style="font-size: 10px;" v-if="data.item.status === 'OPERATIONAL'" color="orange" outlined>
          {{ $t('SWISH_MERCHANT.STATUSES.OPERATIONAL') }}
        </v-chip>

        <v-chip style="font-size: 10px;" v-if="data.item.status === 'ERROR'" color="red" outlined>
          {{ $t('SWISH_MERCHANT.STATUSES.ERROR') }}
        </v-chip>

      </template>

      <template #cell(action)="data">
        <div class="text-right" v-if="!view_only">
          <a
            class="btn btn-icon btn-light btn-sm mx-2"
            @click.prevent="edit_item_clicked(data.item)"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/assets/svg/Write.svg"></inline-svg>
            </span>
          </a>
          <a
            class="btn btn-icon btn-light btn-sm mx-2"
            @click.prevent="delete_item_clicked(data.item)"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
            </span>
          </a>
        </div>
      </template>
      </b-table>

      <p class="ml-4" v-if="total_rows === 0">{{$t('SWISH_MERCHANT.NO_RECORDS_FOUND')}}</p>

    </div>

  </div>

</template>


<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';

export default {

  name: 'SwishMerchantTable',

  props: ['items'],
  emits: ['select', 'delete', 'create'],

  mixins: [ toasts ],

  components: {

  },

  watch: {

  },

  computed: {
    is_mobile() {
      return is_mobile();
    },

    fields() {
      return [
        {
          key: 'company.name',
          label: this.$t('COMPANY.NAME'),
          sortable: false,
          mobile: true,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },
        {
          key: 'created_at',
          label: this.$t('SWISH_MERCHANT.CREATED_AT'),
          sortable: false,
          mobile: false,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },
        {
          key: 'status',
          label: this.$t('SWISH_MERCHANT.STATUS'),
          sortable: false,
          mobile: true,
          tdClass: 'td-short2',
          thClass: 'td-short2',
          formatter: (_, __, item) => {
            return this.$t('SWISH_MERCHANT.STATUSES.' + item.status);
          }
        },
        {
          key: 'action',
          label: '',
          tdClass: 'td-actions',
          thClass: 'td-actions',
          sortable: false
        }
      ]
    }
  },

  methods: {

    ajax_pagination: function(ctx, callback) {
      const vm = this;

      let params = {
        pageNo: ctx.currentPage,
        pageSize: ctx.perPage,
        flag: 0,
        po: vm.searchOrderNumber,
        styNum: vm.searchStyleNumber
      };

      this.get_page_count();
      this.load_pagination(callback);

      return null;
    },

    refresh() {
      this.$refs['swish_merchant-table'].refresh();
    },

    get_options() {
      return {
        text: ''
      };
    },

    async load_pagination(callback) {
      const options = this.get_options();

      try {
        const res = await axios.post(`/swish/merchant/pagination`, { options: options, orderBy: this.orderBy, page: this.current_page, limit: this.per_page });

        if (res.status === 200) {
          var arr = res.data;

          if (callback) {
            callback(arr || []);
            return;
          }
        }
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.AN_ERROR_OCCURED'));
        console.error('pagination get error', err);
      }

    },

    async get_page_count() {

      const options = this.get_options();

      try {
        const res = await axios.post(`/swish/merchant/pagination/count`, { options: options, orderBy: this.orderBy });

        if (res.status === 200) {
          this.total_rows = res.data.count;
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.AN_ERROR_OCCURED'));
      }

    },

    async edit_item_clicked(item) {
      this.$nextTick(()=>{
        this.$emit('select', item);
      });
    },

    async delete_item_clicked(item) {
      this.$emit('delete', item);
    },

  },

  mounted() {

    if (this.is_mobile) {
      this.fields = this.fields.filter((field) => {
        return field.mobile;
      });
    }
  },

  data() {
    return {

      current_page: 1,
      per_page: 100,
      total_rows: 0,

      filters: {
        text: ''
      },


      show_form: true,

    };
  }
};

</script>


<style lang="scss" scoped>
  @import "@/assets/sass/components/forms/_memlist_table.scss";
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>
