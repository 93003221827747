<template>
  <b-modal ref="swish-merchant-editor-modal" hide-footer size="lg">
    <SwishMerchantEditor
      :item="item"
      @close="$refs['swish-merchant-editor-modal'].hide()"
      @created="swish_merchant_created"
      @updated="swish_merchant_updated"
    />
  </b-modal>
</template>

<style lang="scss" scoped>
:deep .modal-xl {
  @media (max-width: 1024px) {
    max-width: 100% !important;
  }
  @media (min-width: 1025px) {
    max-width: 80% !important;
    padding-right: 17px;
    padding-left: 17px;
  }

}
</style>
<script>
import axios from 'axios';
import SwishMerchantEditor from './SwishMerchantEditor.vue';

export default {
  name: 'SwishMerchantEditorModal',
  data() {
    return {

    };
  },

  props: ['item'],
  emits: ['created', 'updated'],
  components: {
    SwishMerchantEditor
  },

  async mounted() {

  },

  methods: {

    swish_merchant_created(item) {
      this.$emit('created', item);
    },

    swish_merchant_updated(item) {
      this.$emit('updated', item);
    },

    show() {
      this.$refs['swish-merchant-editor-modal'].show();
    },

    close() {
      this.$refs['swish-merchant-editor-modal'].hide();
    },

  },


};
</script>
