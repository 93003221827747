<template>
  <div class="no-padding">
    <div style="height: 100%;">

      <TestSwishPaymentModal
        ref="test-swish-payment-modal"
        :merchant="local_item"
      />

      <input hidden type="file" id="file" ref="file" v-on:change="handle_file_upload" />

      <b-form ref="form" @submit.prevent="on_submit" class="mt-8">
        <b-row>
          <b-col cols="8">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('COMPANY.COMPANY')">

                <b-form-select
                  :disabled="true"
                  v-model="item.company_id"
                  :options="company_options">
                </b-form-select>

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="8">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('SWISH_MERCHANT.CREATED_AT')">

              <b-form-input
                :disabled="true"
                v-model="item.created_at" />

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="8">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('SWISH_MERCHANT.KEY_FILE')">

                <b-form-input
                  :disabled="true"
                  v-model="key_file_name"
                />

            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-button variant="primary" @click.prevent="upload_file('key')" >{{ $t('COMMON.UPLOAD') }}</b-button>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="8">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('SWISH_MERCHANT.CERT_FILE')">

                <b-form-input
                  :disabled="true"
                  v-model="cert_file_name" />

            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-button variant="primary" @click.prevent="upload_file('cert')" >{{ $t('COMMON.UPLOAD') }}</b-button>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="8">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('SWISH_MERCHANT.PAYEE_ALIAS')">

                <b-form-input
                  v-model="local_item.payee_alias" />


            </b-form-group>
          </b-col>
        </b-row>

        <p style="margin-top: 42px;"></p>

        <b-row v-if="local_item.id">

          <b-col cols="8">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('SWISH_MERCHANT.FINALIZE_SETUP')">

              <b-button variant="outline-success" @click.prevent="create_swish_test()">
                {{$t('SWISH_MERCHANT.CREATE_TEST')}}
              </b-button>

            </b-form-group>


          </b-col>
        </b-row>

        <p style="margin-top: 42px;"></p>

        <b-row>
          <b-col cols="6">


          </b-col>
          <b-col cols="6">

            <RightModalSaveAndCloseButtons
              :text="$t('COMMON.SAVE')"
              :spin="true"
              @clicked="on_submit"
              @close="$emit('close')"
              ref="save_button"
            />

          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import dayjs from 'dayjs';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';

import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';
import TestSwishPaymentModal from '@/view/pages/ml/swish_merchant/TestSwishPaymentModal.vue';

export default {

  name: 'SwishMerchantEditor',

  props: ['item'],
  emits: ['created', 'updated'],
  mixins: [ toasts ],

  components: {
    RightModalSaveAndCloseButtons,
    TestSwishPaymentModal
  },

  watch: {

    item: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        if (this.item) {
          this.local_item = { ...this.item };
        }
      }
    },

    'local_item.company_id'() {

    }
  },

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings']),

    company_options() {
      return this.companies.map((company) => {
        return {
          text: company.name,
          value: company.company_id
        };
      });
    },

    creating() {
      return this.local_item.id === null || this.local_item.id === undefined;
    },

    key_file_name() {
      if (this.files.key) {
        return this.files.key.name;
      }

      if (this.local_item.key_file) {
        return this.local_item.key_file.name;
      }

      return '';
    },

    cert_file_name() {
      if (this.files.cert) {
        return this.files.cert.name;
      }

      if (this.local_item.cert_file) {
        return this.local_item.cert_file.name;
      }

      return '';
    },

  },


  mounted() {

    if (this.item) {
      this.local_item = { ...this.item };
    }

    if (this.creating) {
      this.local_item = {};
      this.local_item.company_id = this.currentCompanyId;
    }
    else {
      this.files.key = this.local_item.key_file;
      this.files.cert = this.local_item.cert_file;
    }
  },

  methods: {

    async create_swish_test() {
      try {
        this.$refs['test-swish-payment-modal'].show();
      }
      catch (err) {
        console.error('create_swish_test error', err);
      }
    },

    handle_file_upload() {
      this.file = this.$refs.file.files[0];

      if (this.file.size > (50000000)) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.MUST_BE_LESS'));
        return;
      }

      this.upload_cert_file();
    },

    async upload_cert_file() {
      try {
        let formData = new FormData();
        formData.append('file', this.file);

        formData.append('file_source', 'swish');
        formData.append('file_type', 'SWISH_CERT');

        formData.append('company_id', this.currentCompanyId);
        formData.append('period_id', this.currentPeriodId);

        const res = await axios.post('/fileupload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });

        if (res.status === 201) {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.FILES.UPLOADED'));

          const uploaded_file = res.data.fileobjs[0];

          this.files[this.current_file] = res.data.fileobjs[0];

          this.files = { ...this.files };

          this.local_item[this.current_file + '_file_id'] = uploaded_file.file_id;

          return res;
        }

      }
      catch (err) {
        console.error('upload_cert_file', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.UNABLE_UPLOAD'));
    },

    upload_file(type) {
      this.current_file = type;

      this.$refs.file.click();
    },

    async on_submit() {

      if (this.creating) {
        await this.create_swish_merchant();
      }
      else {
        await this.update_swish_merchant();
      }

      this.$refs['save_button'].stop();
    },

    async post_swish_merchant(company_id, payee_alias, cert_file_id, key_file_id) {
      try {
        const response = await axios.post(`/swish/merchant/${company_id}`, {
          company_id,
          payee_alias,
          cert_file_id,
          key_file_id
        });

        if (response.status === 201) {
          return response.data;
        }

        throw `invalid response code ${response.status}`;
      }
      catch (err) {
        throw `catched error: ${err}`;
      }
    },

    async put_swish_merchant(id, payee_alias, cert_file_id, key_file_id) {
      try {
        const response = await axios.put(`/swish/merchant/${id}`, {
          payee_alias,
          cert_file_id,
          key_file_id,
        });

        if (response.status === 201) {
          return response.data;
        }

        throw `invalid response code ${response.status}`;
      }
      catch (err) {
        throw `catched error: ${err}`;
      }
    },


    async create_swish_merchant() {
      try {

        const item = await this.post_swish_merchant(this.local_item.company_id, this.local_item.payee_alias, this.files['cert'].file_id, this.files['key'].file_id);

        this.local_item.id = item.id;
        this.local_item.status = item.status;
        this.local_item.created_at = item.created_at;

        this.local_item = { ...this.local_item }

        this.toastr('success', this.$t('COMMON.OK'), this.$t('COMMON.CREATED'));
        this.$emit('created', item);
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_CREATE'));
      }
    },


    async update_swish_merchant() {
      try {

        await this.put_swish_merchant(this.local_item.company_id, this.local_item.payee_alias, this.files.cert_file_id, this.files.key_file_id);

        this.toastr('success', this.$t('COMMON.OK'), this.$t('COMMON.UPDATED'));
        this.$emit('updated', this.local_item);
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_CREATE'));
      }
    },

  },


  data() {
    return {

      local_item: {},

      file: null,

      current_file: null,

      files: {

      },

    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
